/**
 * Shim to use Backbone Radio with Marionette.
 *
 * Copied from:
 * https://gist.github.com/thejameskyle/48afb443b8c8c6ee4f46
 *
 * Created by Jason.Bell on 3/3/2015.
 */
(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        define(['backbone.marionette', 'backbone.radio', 'underscore'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('backbone.marionette'), require('backbone.radio'), require('underscore'));
    } else {
        factory(root.Backbone.Marionette, root.Backbone.Radio, root._);
    }
}(this, function(Marionette, Radio, _) {
    'use strict';

    Marionette.Application.prototype._initChannel = function () {
        this.channelName = _.result(this, 'channelName') || 'global';
        this.channel = _.result(this, 'channel') || Radio.channel(this.channelName);
    }
}));
