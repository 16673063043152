// This file is needed to properly browserify Backbone/Marionette
// and to do some basic setup to avoid using globals.

// Using a single file like this allows the unit tests to be built
// and run without doing this setup in each test file
//
// in browser context, will be the window
// prevents directly using 'window'
var _global = _global || (function() { return window; })();

// load promise
if (!_global.Promise) {
    require('core-js/fn/promise'); 
}

// Get our $ that we will attach to Backbone
var $ = require('jquery');
_global.$ = $;
_global.jQuery = $;
require('jquery-migrate'); // Remove this once all JQMIGRATE messages are dealt with

// Set up underscore and attach it to our global
var _ = require('underscore');
_global._ = _global._ || _;

var Backbone = require('backbone');
_global.Backbone = _global.Backbone || Backbone;

// Properly setup $ in Backbone
// bootstrap needs this as well
Backbone.$ = Backbone.$ || $;

// Load Bootstrap
require('bootstrap');

// Load jquery datatables
require('datatables.net-bs')( window, $ );

// Load floatthead
require('floatthead');

// Prevent dataTables from using alerts to report HTTP responses other than 200.
// The error event will be used instead.
Backbone.$.fn.dataTable.ext.errMode = 'none';

// rest of infrastructure
var Marionette = require('backbone.marionette');
_global.Marionette = _global.Marionette || Marionette;

var Spinner = require('spin');
_global.Spinner = _global.Spinner || Spinner;

// Load the shim to allow Marionette to use Radio instead of Wreqr
require('backbone.radio');
require('radio.shim');

// For Marionette Inspector
// (must come after loading Backbone and Marionette)
if (window && window.__agent) {
    window.__agent.start(Backbone, Marionette);
}

require('backbone.base-router');

// Ace Template
require('../vendor/ace-extra');
var ace = require('ace');
_global.ace = _global.ace || ace;

// load URI.js (Polyfill library for URL API )
// This can be removed once OSO no longer support IE11
var URI = require('urijs');
_global.URI = URI;

// OSO version of easyPieChart, for dials in performance center
require('../js/utils/jquery.easypiechart.oso');

// Script used to implement DataTables with Spring JPA
require('../vendor/jquery.spring-friendly');

// jquery plugin to do the opposite of $.param(..)
require('../vendor/jquery-deparam');

// jquery caching
require('../vendor/jquery-ajax-localstorage-cache');

// jquery position-change-listener
require('../vendor/position-change-listener');

// Script used to load highcharts
var Highcharts = require('../vendor/highcharts');
_global.Highcharts = _global.Highcharts || Highcharts;

// Return _global for use in the app
module.exports = _global;
