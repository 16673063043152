/**
 * jQuery plugin to track element position change.
 * 
 * Just provide the jQueryElement object as input
 * plugin callback if there is any change in position
 * 
 * Sample usage :
 * $('#element').onPositionChanged(
 *    function (offset) {console.log(offset)}, 
 *    100
 * );
 */

(function ($) {

    /**
     * @param {function} callback - method to get back latest change
     * @param {number} millis time interval to listen change.
     */
    $.fn.onPositionChanged = function (callback, millis) {
        if (millis == null) {
            millis = 500;
        } 
        var el = $(this[0]); 
        if (el.length < 1) return el;

        var lastPos = null;
        var lastOff = null;
        var intervalObj = setInterval(function () {
            if (el == null || el.length < 1) return el; // abort if element is non existend eny more
            if (lastPos == null) lastPos = el.position();
            if (lastOff == null) lastOff = el.offset();
            var newPos = el.position();
            var newOff = el.offset();
            if (lastPos.top != newPos.top || lastPos.left != newPos.left) {
                $(this).trigger('onPositionChanged', { lastPos: lastPos, newPos: newPos });
                if (typeof (callback) == "function") callback(lastPos, newPos);
                lastPos = el.position();
            }
            if (lastOff.top != newOff.top || lastOff.left != newOff.left) {
                $(this).trigger('onOffsetChanged', { lastOff: lastOff, newOff: newOff});
                if (typeof (callback) == "function") callback(lastOff, newOff);
                lastOff= el.offset();
            }
        }, millis);

        function stopListener () {
            clearInterval(intervalObj);
        }

        return {
            el : el, 
            off : stopListener
        };
    };

})(jQuery);